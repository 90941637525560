import {AppContext} from 'App';
import classnames from 'classnames';
import Button from 'components/Button';
import fontColorContrast from 'font-color-contrast';
import {hasFlag} from 'helpers/bitwise';
import {Messenger} from 'managers/messenger';
import queryString from 'query-string';
import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useRouteMatch, withRouter} from 'react-router';
import {
  ROUTE_INTERCOM_EVOLUTION_ID,
  ROUTE_INTERCOM_FEED,
  ROUTE_INTERCOM_FEEDBACK,
  ROUTE_INTERCOM_ROADMAP,
  ROUTE_WIDGET_EVOLUTION_ID,
  ROUTE_WIDGET_FEED,
  ROUTE_WIDGET_FEEDBACK,
  ROUTE_WIDGET_ROADMAP,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {F_PAGE_FEED, F_PAGE_FEEDBACK, F_PAGE_ROADMAP} from 'services/project';
import './_Styles.scss';
import {motion} from 'framer-motion';

const Header = (props) => {
  const match = useRouteMatch();

  if (match == null) {
    return null;
  }
  if (
    [
      ROUTE_WIDGET_FEEDBACK,
      ROUTE_INTERCOM_FEEDBACK,
      ROUTE_WIDGET_FEED,
      ROUTE_INTERCOM_FEED,
      ROUTE_WIDGET_ROADMAP,
      ROUTE_INTERCOM_ROADMAP,
    ].includes(match.path) === true
  ) {
    return <DefaultHeader {...props} />;
  }
  if (
    [ROUTE_WIDGET_EVOLUTION_ID(), ROUTE_INTERCOM_EVOLUTION_ID()].includes(
      match.path
    ) === true
  ) {
    return <HeaderEvolution {...props} />;
  }
  return null;
};

const DefaultHeader = ({isIntercom}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const qs = queryString.parse(window.location.search);
  const isPreview = qs.preview === 'true';

  const {theme} = useContext(AppContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const showRoadmap = hasFlag(F_PAGE_ROADMAP, project.widgetContentFlags);
  const showFeedback = hasFlag(F_PAGE_FEEDBACK, project.widgetContentFlags);
  const showFeed = hasFlag(F_PAGE_FEED, project.widgetContentFlags);

  const sectionsStr = project.widgetSectionOrder || 'feed;roadmap;feedback';
  const sections = sectionsStr.split(';');
  const filteredSections = sections.filter((s) => {
    if (showRoadmap === false && s === 'roadmap') {
      return false;
    }
    if (showFeedback === false && s === 'feedback') {
      return false;
    }
    if (showFeed === false && s === 'feed') {
      return false;
    }
    return true;
  });

  const {general} = theme ?? {};
  const {background} = general ?? {};
  const {type, primaryColor, secondaryColor} = background ?? {};

  const textColor = fontColorContrast(
    (['shape'].includes(type)
      ? secondaryColor?.slice(0, 7)
      : primaryColor?.slice(0, 7)) ||
      project.widgetThemeColor ||
      '#fff'
  );

  const handleClose = () => {
    Messenger.sendCloseView();

    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({action: 'CLOSE_CHANGELOG', fromJimo: true})
      );
    }
  };

  return (
    <motion.div
      className={classnames('widget-header navigation-bar', {
        'is-preview': isPreview === true,
      })}
      style={{
        ...(type === 'shape'
          ? {
              backgroundColor: secondaryColor,
            }
          : {}),
      }}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 30,
        mass: 1,
      }}
    >
      {[showFeed, showRoadmap, showFeedback].filter((s) => s === true).length >
      1 ? (
        <>
          {filteredSections.map((s, index) => {
            if (s === 'feed') {
              return (
                <div
                  style={{color: textColor}}
                  className={classnames('nav-btn', {
                    selected: [ROUTE_WIDGET_FEED, ROUTE_INTERCOM_FEED].includes(
                      match.path
                    ),
                  })}
                  onClick={() => {
                    if (isIntercom === true) {
                      return history.push(ROUTE_INTERCOM_FEED);
                    }
                    history.push(ROUTE_WIDGET_FEED);
                  }}
                >
                  Feed
                </div>
              );
            } else if (s === 'roadmap') {
              return (
                <div
                  style={{color: textColor}}
                  className={classnames('nav-btn', {
                    selected: [
                      ROUTE_WIDGET_ROADMAP,
                      ROUTE_INTERCOM_ROADMAP,
                    ].includes(match.path),
                  })}
                  onClick={() => {
                    if (isIntercom === true) {
                      return history.push(ROUTE_INTERCOM_ROADMAP);
                    }
                    history.push(ROUTE_WIDGET_ROADMAP);
                  }}
                >
                  {index === 0 && <i className="icon-feed" />}
                  <Trans i18nKey="others.upcomingTabButtonRoadmap"></Trans>
                </div>
              );
            } else if (s === 'feedback') {
              return (
                <div
                  style={{color: textColor}}
                  className={classnames('nav-btn', {
                    selected: [
                      ROUTE_WIDGET_FEEDBACK,
                      ROUTE_INTERCOM_FEEDBACK,
                    ].includes(match.path),
                  })}
                  onClick={() => {
                    if (isIntercom === true) {
                      return history.push(ROUTE_INTERCOM_FEEDBACK);
                    }
                    history.push(ROUTE_WIDGET_FEEDBACK);
                  }}
                >
                  {index === 0 && <i className="icon-feed" />}
                  <Trans i18nKey="others.upcomingTabButtonGiveFeedback"></Trans>
                </div>
              );
            }
            return <></>;
          })}
        </>
      ) : (
        <div className="header-title" style={{color: textColor}}>
          {showFeedback === true ? (
            <Trans
              i18nKey="others.widgetFeedbackTopTitle"
              values={{projectName: project.name}}
            />
          ) : (
            <Trans
              i18nKey="others.widgetAnnouncementsHeader"
              values={{projectName: project.name}}
            />
          )}
        </div>
      )}

      {isIntercom !== true && (
        <Button className="btn-close-view" muted iconOnly onClick={handleClose}>
          <i className="icon-close" />
        </Button>
      )}
    </motion.div>
  );
};

const HeaderEvolution = withRouter((props) => {
  const history = useHistory();
  const {isIntercom} = props;

  const handleClose = () => {
    Messenger.sendCloseView();

    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({action: 'CLOSE_CHANGELOG', fromJimo: true})
      );
    }
  };

  return (
    <motion.div
      className="widget-header widget-header-evolution"
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      transition={{
        type: 'spring',
        stiffness: 400,
        damping: 30,
        mass: 1,
      }}
    >
      <Button
        className="btn-back-feedback"
        iconOnly
        muted
        onClick={() => {
          return history.push(
            isIntercom === true ? ROUTE_INTERCOM_FEED : ROUTE_WIDGET_FEED
          );
        }}
      >
        <i className="icon-chevron-left" />
      </Button>
      <div className="detail-title">Post Detail</div>
      {isIntercom !== true && (
        <Button className="btn-close-view" muted iconOnly onClick={handleClose}>
          <i className="icon-close" />
        </Button>
      )}
    </motion.div>
  );
});

export default Header;
