import classnames from 'classnames';
import AnalyticViewTrigger from 'components/AnalyticViewTrigger';
import CardPush from 'components/CardPush';
import EvolutionStats from 'components/EvolutionStats';
import i18n from 'conf/i18n';
import {hasFlag} from 'helpers/bitwise';
import useStormwindMessenger from 'hooks/useStormwindMessenger';
import {Messenger} from 'managers/messenger';
import queryString from 'query-string';
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';
import {
  ROUTE_WIDGET_FEEDBACK,
  ROUTE_WIDGET_EVOLUTION_ID,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {EventContext, EventType} from 'services/analytic';
import {F_OPTION_PORTAL_DISPLAY_FEED} from 'services/evolution';
import {
  F_EXTRA_WIDGET_COLLAPSE_POST,
  F_PAGE_FEED,
  F_PAGE_FEEDBACK,
} from 'services/project';
import {Post} from 'shared/front/components/Post';
import {EmptyStateFeed} from '../../../components/EmptyStateFeed';
import './_Styles.scss';
import {generalActions} from 'actions';
import {motion, LayoutGroup} from 'framer-motion';
import {AppContext} from 'App';

const POST_PREVIEW_REQUEST = 'POST_PREVIEW_REQUEST';

export const FeedContext = createContext();

const Feed = ({isIntercom}) => {
  const dispatch = useDispatch();

  const rawEvolutions = useSelector((state) =>
    generalSelector.getEvolutions(state)
  );
  const project = useSelector((state) => generalSelector.getProject(state));
  const previewedPost = useSelector((state) => state.general.previewedPost);

  const {addFontFamily} = useContext(AppContext);

  const scrollRef = useRef(null);

  const [focused, setFocused] = useState(null);
  const [overrideTheme, setOverrideTheme] = useState(null);
  const [overrideLanguage, setOverrideLanguage] = useState(null);

  const qs = queryString.parse(window.location.search);
  const isPreview = qs.preview === 'true';

  useStormwindMessenger({
    onPostPreviewUpdate: ({evolution}) => {
      dispatch(generalActions.setPreviewedPost(evolution));
    },
    onChangelogOverrideTheme: ({theme}) => {
      setOverrideTheme(theme);
    },
    onChangelogOverrideLanguage: ({language}) => {
      setOverrideLanguage(language);
    },
  });

  useEffect(() => {
    if (isPreview === true) {
      const interval = setInterval(() => {
        if (window.parentIFrame != null) {
          window.parentIFrame.sendMessage(
            JSON.stringify({action: POST_PREVIEW_REQUEST, fromJimo: true})
          );
          clearInterval(interval);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');

    if (scrollPosition != null) {
      scrollRef.current.scrollTop = scrollPosition;
      sessionStorage.removeItem('scrollPosition');
    }
  }, []);

  const classNames = classnames('widget-feed', {
    'has-feedback-enabled':
      hasFlag(F_PAGE_FEEDBACK, project.widgetContentFlags) === true,
    'has-whitelabel': project.whiteLabeling === true,
    'is-preview': isPreview === true,
  });

  if (hasFlag(F_PAGE_FEED, project.widgetContentFlags) === false) {
    return <Redirect to={ROUTE_WIDGET_FEEDBACK} />;
  }

  const evolutions = [
    ...(previewedPost != null ? [previewedPost] : []),
    ...rawEvolutions
      .filter(
        (e) =>
          hasFlag(F_OPTION_PORTAL_DISPLAY_FEED, e.optionsFlags) &&
          e.uid !== previewedPost?.uid
      )
      .sort((a, b) => {
        return (
          new Date(b.lastStepChangeAt).getTime() -
          new Date(a.lastStepChangeAt).getTime()
        );
      }),
  ];

  const theme =
    overrideTheme != null ? overrideTheme : project.changelogStyle || {};

  const language = isPreview
    ? overrideLanguage != null
      ? overrideLanguage
      : null
    : i18n.language.toUpperCase();

  const enableCollapse = hasFlag(
    F_EXTRA_WIDGET_COLLAPSE_POST,
    project.extraFlags
  );

  const saveScrollPosition = () => {
    const scrollPosition = scrollRef.current.scrollTop;

    sessionStorage.setItem('scrollPosition', scrollPosition);
  };

  return (
    <FeedContext.Provider
      value={{
        isPreview,
        theme,
      }}>
      <div className={classNames} ref={scrollRef}>
        {evolutions.length === 0 ? (
          <EmptyStateFeed />
        ) : (
          <motion.div
            className={classnames('evolutions-wrapper', {
              'is-intercom': isIntercom === true,
            })}
            initial={{opacity: 0, x: 40}}
            animate={{opacity: 1, x: 0}}
            transition={{
              type: 'spring',
              stiffness: 789,
              damping: 65,
              mass: 1,
            }}>
            {evolutions.map((e) => (
              <FeedEvolution
                evolution={e}
                enableCollapse={enableCollapse}
                language={language}
                previewedPost={previewedPost}
                focused={focused}
                setFocused={setFocused}
                isIntercom={isIntercom}
                isPreview={isPreview}
                theme={theme}
                addFontFamily={addFontFamily}
                onClick={saveScrollPosition}
              />
            ))}
          </motion.div>
        )}
      </div>
    </FeedContext.Provider>
  );
};

const FeedEvolution = ({
  evolution: e,
  enableCollapse,
  language,
  previewedPost,
  focused,
  setFocused,
  isIntercom,
  isPreview,
  theme,
  addFontFamily,
  onClick,
}) => {
  const isOldVersionPost = !(e?.steps?.[0]?.blocks?.length > 0);
  const isEnableCollapse =
    enableCollapse &&
    (previewedPost?.uid === e.uid && previewedPost.preventCollapse === true) !==
      true;

  const PostWrapper = ({children}) => (
    <div key={`post-${e.uid}`} onClick={onClick}>
      {isEnableCollapse ? (
        <Link
          to={{
            pathname: ROUTE_WIDGET_EVOLUTION_ID(e.uid),
          }}>
          {children}
        </Link>
      ) : (
        <>{children}</>
      )}
    </div>
  );

  const ThumbnailWrapper = ({children}) =>
    isPreview ? (
      <>{children}</>
    ) : (
      <LayoutGroup>
        <motion.div
          layoutId={`motion-post-${e.uid}`}
          layout
          exit={{opacity: 1}}>
          {children}
        </motion.div>
      </LayoutGroup>
    );

  return useMemo(
    () =>
      isOldVersionPost ? (
        <CardPush
          className="fade-in"
          key={e.uid}
          evolution={e}
          widgetMode
          focused={focused === e.uid}
          setFocused={() => setFocused(e.uid)}
          onClose={() => setFocused(null)}
          isIntercom={isIntercom}
          enableCollapse
          showTimestamp
          isPreview={isPreview}
        />
      ) : (
        <>
          <PostWrapper>
            <Post
              post={e}
              theme={theme}
              language={language}
              onLaunchPoke={({pokeId, defaultUrl}) => {
                Messenger.sendLaunchPoke({
                  pokeId,
                  defaultUrl,
                });
              }}
              onUrlClick={(url) => {
                Messenger.sendOpenCtaUrl(url);
              }}
              onImageClick={(src) => {
                Messenger.sendOpenModalImage(src);
              }}
              PostBlockSocialsContent={EvolutionStats}
              AnalyticViewTrigger={() => {
                if (isPreview === true) {
                  return null;
                }
                return (
                  <AnalyticViewTrigger
                    evolution={e}
                    context={EventContext.PORTAL_WIDGET}
                    type={EventType.EVOLUTION_SEEN}
                  />
                );
              }}
              enableCollapse={isEnableCollapse}
              ThumbnailWrapper={ThumbnailWrapper}
              addFontFamily={addFontFamily}
            />
          </PostWrapper>
        </>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [e.uid, ...(isPreview && theme ? Object.entries(theme) : [])]
  );
};

export default Feed;
