import React from 'react';
import {object} from 'prop-types';
import Avatar1 from './imgs/1.svg';
import Avatar2 from './imgs/2.svg';
import Avatar3 from './imgs/3.svg';
import Avatar4 from './imgs/4.svg';
import Avatar5 from './imgs/5.svg';
import Avatar6 from './imgs/6.svg';
import Avatar7 from './imgs/7.svg';
import Avatar8 from './imgs/8.svg';
import './_Styles.scss';

const avatars = [
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
];

const colors = [`y-200`, `p-200`, `o-200`, `g-200`, `b-150`, `n-200`, `r-200`];

const propTypes = {
  jimer: object,
};

const defaultProps = {
  jimer: {},
};

const Avatar = ({jimer, hasBackground = false}) => {
  // generate a number between 0 and 7 based on the string jimer.uid
  const avatarIndex = jimer?.uid
    ? jimer.uid
        .split('')
        .map((c) => c.charCodeAt(0))
        .reduce((a, b) => a + b, 0) % avatars.length
    : 0;
  const colorIndex = jimer?.uid
    ? jimer.uid
        .split('')
        .map((c) => c.charCodeAt(0))
        .reduce((a, b) => a + b, 0) % colors.length
    : 0;

  const avatar = avatars[avatarIndex];
  const color = colors[colorIndex];

  return (
    <div
      className="avatar-img-wrapper"
      style={{backgroundColor: `var(--${color}`}}
    >
      <img className="avatar" src={avatar} alt="avatar" />
    </div>
  );
};

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export default Avatar;
