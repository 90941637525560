import {
  OPINION_TYPE_EMOJI,
  OPINION_TYPE_SMILEY,
  parseOpinionValue,
} from '../components/BlockOpinion';
import {
  BLOCK_CHOICE,
  BLOCK_NPS,
  BLOCK_OPEN_QUESTION,
  BLOCK_OPINION,
  BLOCK_SLIDER,
  isInteractiveBlock,
} from '../constants/blocks';
import {isInteractiveStep} from '../constants/steps';

export const EVALUATE_CLOSE = 'close';
export const EVALUATE_COMPLETED = 'completed';

const STEP_CONDITION_ACTION_TYPE_GO_TO_STEP = 'GO_TO_STEP';
const STEP_CONDITION_ACTION_TYPE_DISMISS = 'DISMISS';
const STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE = 'LAUNCH_EXPERIENCE';

const operators = {
  EQUAL_TO: 'EQUAL_TO',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  GREATER_THAN: 'GREATER_THAN',
  LESSER_THAN: 'LESSER_THAN',
  CONTAINS: 'CONTAINS',
  DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
};

export const evaluate = ({steps, step, stepIndex, response}) => {
  const {triggers = []} = step;
  let matchingTrigger = null;
  const isLastInteractiveStep =
    steps
      .slice(stepIndex + 1, steps.length)
      .some((s) => isInteractiveStep(s)) === false;
  const blockInteractive = step.blocks.find((b) => isInteractiveBlock(b.type));

  if (blockInteractive?.type === BLOCK_CHOICE) {
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (operand === 'ALL' || operand == null) {
        return areEqual(
          value.split(';'),
          response.map((r) => r.uid || r)
        );
      }
      if (operand === 'NOT') {
        return !areEqual(
          value.split(';'),
          response.map((r) => r.uid || r)
        );
      }
      if (operand === 'AT_LEAST_ONE_OF') {
        return response.some((v) => value.split(';').includes(v.uid));
      }
      if (operand === 'NONE_OF') {
        return !response.some((v) => value.split(';').includes(v.uid));
      }
      return false;
    });
  }
  if (blockInteractive?.type === BLOCK_OPEN_QUESTION) {
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (operand === operators.EQUAL_TO) {
        return value === response;
      }
      if (operand === operators.NOT_EQUAL_TO) {
        return value !== response;
      }
      if (operand === operators.CONTAINS) {
        return response?.includes?.(value) === true;
      }
      if (operand === operators.DOES_NOT_CONTAIN) {
        return response?.includes?.(value) === false;
      }
      return null;
    });
  }
  if (blockInteractive?.type === BLOCK_OPINION) {
    const opinionValue = parseOpinionValue(blockInteractive.value);
    response = response.toString();
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (
        [OPINION_TYPE_SMILEY, OPINION_TYPE_EMOJI].includes(opinionValue.type)
      ) {
        if (operand === 'ALL') {
          return value === response.toString();
        }
        if (operand === 'NOT') {
          return value !== response.toString();
        }
        if (operand === 'AT_LEAST_ONE_OF') {
          return (value || '')
            .split(';')
            .map((o) => o)
            .includes(response.toString());
        }
        if (operand === 'NONE_OF') {
          return !(value || '')
            .split(';')
            .map((o) => o)
            .includes(response.toString());
        }
      } else {
        if (operand === operators.EQUAL_TO) {
          return parseInt(value, 10) === parseInt(response, 10);
        }
        if (operand === operators.NOT_EQUAL_TO) {
          return parseInt(value, 10) !== parseInt(response, 10);
        }
        if (operand === operators.GREATER_THAN) {
          return parseInt(value, 10) < parseInt(response, 10);
        }
        if (operand === operators.LESSER_THAN) {
          return parseInt(value, 10) > parseInt(response, 10);
        }
      }
      return null;
    });
  }
  if (blockInteractive?.type === BLOCK_SLIDER) {
    response = response.toString();
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (operand === operators.EQUAL_TO) {
        return parseInt(value, 10) === parseInt(response / 10, 10);
      }
      if (operand === operators.NOT_EQUAL_TO) {
        return parseInt(value, 10) !== parseInt(response / 10, 10);
      }
      if (operand === operators.GREATER_THAN) {
        return parseInt(value, 10) < parseInt(response / 10, 10);
      }
      if (operand === operators.LESSER_THAN) {
        return parseInt(value, 10) > parseInt(response / 10, 10);
      }
      return null;
    });
  }
  if (blockInteractive?.type === BLOCK_NPS) {
    matchingTrigger = triggers.find((trigger) => {
      const {conditions = []} = trigger;
      const condition = conditions[0];
      const {operand, value = ''} = condition || {};

      if (operand === operators.EQUAL_TO) {
        return parseInt(value, 10) === parseInt(response, 10);
      }
      if (operand === operators.NOT_EQUAL_TO) {
        return parseInt(value, 10) !== parseInt(response, 10);
      }
      if (operand === operators.GREATER_THAN) {
        return parseInt(value, 10) < parseInt(response, 10);
      }
      if (operand === operators.LESSER_THAN) {
        return parseInt(value, 10) > parseInt(response, 10);
      }
      return null;
    });
  }

  // Jump / End Survey : we close the poke
  // Step / End Survey : we close the poke

  /**
   * Case 1 : No matching trigger and step has endSurvey set to true
   */
  if (matchingTrigger == null && step?.endSurvey === true) {
    return {state: EVALUATE_CLOSE};
  }

  /**
   * Case 2 : No Matching trigger and step has goTo set
   */
  if (matchingTrigger == null && step?.goTo != null) {
    const index = steps.map((s) => s.uid).indexOf(step.goTo?.uid || step.goTo);
    if (index > 0) {
      const isNextStepAfterLastInteractiveStep =
        steps.slice(index, steps.length).some((s) => isInteractiveStep(s)) ===
        false;
      // If next step is after last interactive step : we set survey to completed in the return object along with the next index
      const state =
        isNextStepAfterLastInteractiveStep === true ? EVALUATE_COMPLETED : null;

      return {
        state,
        stepIndex: index,
      };
    }
  }

  /**
   * Case 3 : Matching trigger found
   */
  if (matchingTrigger != null) {
    const {actions} = matchingTrigger;
    const hasDismissAction = actions.some(
      (action) => action.type === STEP_CONDITION_ACTION_TYPE_DISMISS
    );
    const hasGoToStepAction = actions.some(
      (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
    );
    const hasLaunchExperienceAction = actions.some(
      (action) => action.type === STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE
    );

    const filteredActions = actions.filter(
      (action) =>
        action.type !== STEP_CONDITION_ACTION_TYPE_DISMISS &&
        action.type !== STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
    );

    if (hasDismissAction || hasLaunchExperienceAction) {
      return {state: EVALUATE_CLOSE, actions: filteredActions};
    }

    if (hasGoToStepAction) {
      const goToStepAction = actions.find(
        (action) => action.type === STEP_CONDITION_ACTION_TYPE_GO_TO_STEP
      );
      const index = steps.map((s) => s.uid).indexOf(goToStepAction.stepId);

      if (index > 0) {
        const isNextStepAfterLastInteractiveStep =
          steps.slice(index, steps.length).some((s) => isInteractiveStep(s)) ===
          false;
        // If next step is after last interactive step : we set survey to completed in the return object along with the next index
        const state =
          isNextStepAfterLastInteractiveStep === true
            ? EVALUATE_COMPLETED
            : null;
        return {
          state,
          stepIndex: index,
          actions: filteredActions,
        };
      }
    }

    const state = isLastInteractiveStep === true ? EVALUATE_COMPLETED : null;
    return {state, actions: filteredActions, stepIndex: stepIndex + 1};
  }

  /**
   * Case 4 : No matching trigger found and no goTo set
   * if current step was last interactive step : we set survey to completed in the return object along with the next index
   */
  const state = isLastInteractiveStep === true ? EVALUATE_COMPLETED : null;

  return {
    state,
    stepIndex: stepIndex + 1,
  };
};

export function areEqual(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
}
