import classnames from 'classnames';
import Button from 'components/Button';
import {Messenger} from 'managers/messenger';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_STANDALONE_FEED,
  ROUTE_WIDGET_FEEDBACK,
  ROUTE_WIDGET_PREVIEW,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import Prototype from './components/Prototype';
import Sidebar from './components/Sidebar';
import './_Styles.scss';

const StandalonePreview = (props) => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const {evolutionId, stepId} = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isWidgetMode = () => routeMatch.path === ROUTE_WIDGET_PREVIEW();

  const classNames = classnames('s-preview', {
    'is-widget-mode': isWidgetMode(),
  });
  const evolution = props.getEvolutionById(evolutionId);
  const step = evolution.steps.find((s) => s.uid === stepId);

  useEffect(() => {
    if (isWidgetMode() === true) {
      Messenger.sendFullscreenView(true);
    }
    return () => {
      if (isWidgetMode() === true) {
        Messenger.sendFullscreenView(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (evolution == null) {
    return <div className={classNames}>Prototype not found</div>;
  }
  return (
    <div className={classNames}>
      <div className="s-preview-container">
        <div className="top-col">
          <div className="btn-close-wrapper">
            <Button
              iconLeft="icon-chevron-left"
              light
              rounded={false}
              onClick={() => {
                if (history.action === 'POP') {
                  return history.push(
                    isWidgetMode() === true
                      ? ROUTE_WIDGET_FEEDBACK
                      : ROUTE_STANDALONE_FEED
                  );
                } else {
                  return history.goBack();
                }
              }}
            >
              Close
            </Button>
          </div>
        </div>
        <div className="preview-main-wrapper">
          <Prototype widgetMode={isWidgetMode()} step={step} />
          <Sidebar evolution={evolution} step={step}></Sidebar>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getEvolutionById: (evolutionId) =>
    generalSelector.getEvolutionById(state, evolutionId),
});

export default connect(mapStateToProps)(StandalonePreview);
