import classnames from 'classnames';
import Button from 'components/Button';
import Card from 'components/Card';
import {toastDanger} from 'components/Toaster';
import dayjs from 'dayjs';
import React, {useState} from 'react';
import {commentService} from 'services';
import {Swaler} from 'swaler';
import './_styles.scss';
import {useTranslation} from 'react-i18next';
import {toTitleCase} from 'utils/string';
import Avatar from 'shared/front/components/Avatar';

const logger = new Swaler('Comment');

const Comment = ({
  comment,
  onCommentReply,
  onCommentLiked,
  onCommentUnLiked,
  children,
}) => {
  const {t} = useTranslation();

  const [isSubmittingLike, setIsSubmittingLike] = useState(null);
  const [showJimerProfileId, setShowJimerProfileId] = useState(null);
  const [likes, setLikes] = useState(comment?.likes?.length || 0); // set likes to update locally unless we refactor to a more robust like system

  const handleSubmitCommentLike = async () => {
    setIsSubmittingLike(comment.uid);
    setLikes((like) => like + 1);

    try {
      const like = await commentService.createCommentLike(comment.uid);

      setIsSubmittingLike(null);
      onCommentLiked(like);
    } catch (err) {
      logger.error('Submitting comment like failed with error ', err);
      toastDanger('Submitting comment like failed');
      setIsSubmittingLike(null);
      setLikes((like) => like - 1);
    }
  };
  const handleSubmitCommentUnLike = async (like) => {
    setIsSubmittingLike(comment.uid);
    setLikes((like) => like - 1);
    try {
      await commentService.deleteCommentLike(comment.uid, like.uid);

      setIsSubmittingLike(null);
      onCommentUnLiked(comment.uid, like.uid);
    } catch (err) {
      logger.error('Deleting comment like failed with error ', err);
      toastDanger('Deleting comment like failed');
      setIsSubmittingLike(null);
      setLikes((like) => like + 1);
    }
  };

  const hasUsernameGenerated = (username) => {
    const nameSplit = username?.split(' ');

    if (username == null) {
      return false;
    }
    if (nameSplit.length !== 2) {
      return false;
    }
    if (nameSplit[0] !== 'Jimer') {
      return false;
    }
    if (nameSplit[1].length !== 4) {
      return false;
    }
    return true;
  };

  const authorIsJimer = comment.jimer != null ? true : false;
  const author = authorIsJimer === true ? comment.jimer : comment.user;
  const username =
    authorIsJimer === false
      ? author?.username
      : hasUsernameGenerated(author?.username) &&
          author?.externalUsername != null
        ? author?.externalUsername
        : author?.username;

  return (
    <div key={comment.uid} className="comment" id={`comment_${comment.uid}`}>
      <Avatar jimer={comment.jimer} hasBackground />
      <div className="comment-content-wrapper">
        <div className="item">
          <Card>
            <div className="top-wrapper">
              <div
                className={classnames('author body-3', {
                  'is-team': authorIsJimer === false,
                  'is-clickable': authorIsJimer === true,
                })}
                onClick={() => {
                  if (authorIsJimer === true) {
                    setShowJimerProfileId(author.uid);
                  }
                }}
              >
                {author != null ? username : 'Deleted user'}
              </div>
              <div
                className="date-posted body-4"
                title={dayjs(comment.createdAt).format('HH:mm - DD/MM/YYYY')}
              >
                {dayjs(comment.createdAt).fromNow()}
              </div>
            </div>
            <div className="message body-2">{comment.message}</div>
          </Card>
          <div className="actions">
            <Button
              disabled={isSubmittingLike === comment.uid}
              thin
              light
              onClick={() => {
                if (comment.myLike != null) {
                  handleSubmitCommentUnLike(comment.myLike);
                } else {
                  handleSubmitCommentLike(comment);
                }
              }}
              className={classnames('btn-like body-3', {
                'is-liked': comment.myLike != null,
              })}
            >
              {likes > 0 && likes} {likes === 0 ? 'L' : 'l'}ike
              {likes > 1 && 's'}
            </Button>
            <Button
              className="btn-comment-reply body-3"
              thin
              onClick={onCommentReply}
              light
            >
              {toTitleCase(t('common:reply'))}
            </Button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Comment;
