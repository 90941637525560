import classNames from 'classnames';
import BasicFooter from 'components/Footer';
import {Environment} from 'conf/env';
import {hasFlag} from 'helpers/bitwise';
import React from 'react';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {generalSelector} from 'selectors';
import {authService} from 'services';
import {F_EXTRA_PORTAL_STANDALONE_ENABLED} from 'services/project';
import {Swaler} from 'swaler';
import {
  IntercomBasicLayout,
  StandaloneBasicLayout,
  WidgetBasicLayout,
  WidgetDetailLayout,
} from './Layouts';
import {ROUTE_WIDGET_ENTER, ROUTE_WIDGET_PREVIEW} from './routes.const';

const logger = new Swaler('Routes');

export const MyRoute = ({
  component: Component,
  header: Header,
  footer: Footer = BasicFooter,
  layout: Layout,
  footerProps = {},
  layoutProps,
  title = '',
  checkAuth = false,
  isWidgetRoute,
  ...rest
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  return (
    <Route
      {...rest}
      render={(props) => {
        if (checkAuth === true && authService.isAuthenticated() === false) {
          logger.warn(
            `Session has expired redirecting to enter scene to renew the !`
          );
          return <Redirect to={ROUTE_WIDGET_ENTER} />;
        }
        return [
          <Helmet key={1}>
            {title.length !== 0 ? (
              <title>
                {title} &middot; {project.name} Feedback
              </title>
            ) : (
              <title>{project.name} Feedback</title>
            )}

            <body
              className={classNames({
                'widget-mode': isWidgetRoute,
                light: project.portalStyle === 'LIGHT',
                dark: project.portalStyle === 'DARK',
                'preview-widget-bg':
                  props.match.path === ROUTE_WIDGET_PREVIEW(),
              })}
            />
          </Helmet>,

          Environment.NODE_ENV === 'stage' && (
            <div key={2} className="stage-indicator">
              <span role="img" aria-label="stage">
                {' '}
                🚧{' '}
              </span>
              You are in the stage environment
            </div>
          ),
          <Layout
            key={3}
            component={Component}
            header={Header}
            footer={Footer}
            footerProps={footerProps}
            layoutProps={layoutProps}
            {...props}
          />,
        ];
      }}
    />
  );
};

export const IntercomPublicRoute = (props) => (
  <MyRoute layout={IntercomBasicLayout} {...props} isIntercomRoute />
);

export const IntercomPrivateRoute = (props) => (
  <MyRoute layout={IntercomBasicLayout} checkAuth {...props} isIntercomRoute />
);

export const WidgetDetailPublicRoute = (props) => (
  <MyRoute layout={WidgetDetailLayout} {...props} isWidgetRoute />
);

export const WidgetDetailPrivateRoute = (props) => (
  <MyRoute layout={WidgetDetailLayout} checkAuth {...props} isWidgetRoute />
);

export const WidgetPublicRoute = (props) => (
  <MyRoute layout={WidgetBasicLayout} {...props} isWidgetRoute />
);

export const WidgetPrivateRoute = (props) => (
  <MyRoute layout={WidgetBasicLayout} checkAuth {...props} isWidgetRoute />
);

export const StandalonePublicRoute = (props) => {
  const history = useHistory();
  const project = useSelector((state) => generalSelector.getProject(state));

  if (hasFlag(F_EXTRA_PORTAL_STANDALONE_ENABLED, project.extraFlags) === true) {
    return <MyRoute layout={StandaloneBasicLayout} {...props} />;
  }
  history.push('/404');
  return <></>;
};

export const StandalonePrivateRoute = (props) => {
  const history = useHistory();
  const project = useSelector((state) => generalSelector.getProject(state));

  if (hasFlag(F_EXTRA_PORTAL_STANDALONE_ENABLED, project.extraFlags) === true) {
    return <MyRoute layout={StandaloneBasicLayout} checkAuth {...props} />;
  }
  history.push('/404');
  return <></>;
};
